<template>
  <transition name="fade">

    <div class="d-flex flex-column flex-xl-row">
      <!--begin::Content-->
      <div class="flex-lg-row-fluid">
        <div style='line-height: 70px;  margin-top: -18px; background: #f4f4f500; margin-left: -30px; margin-right: -30px; padding-left: 30px; padding-right: 30px; margin-bottom: 8px;'>
          <ul class="nav nav-custom nav-pills border-0 fs-4 fw-bold mb-8" style="display: inline;">
            <li class="nav-item" style="float: left; background: none;">
              <h1 class="text-gray-600 fw-bolder mt-2 my-1 fs-5 bg-title mb-5 card">
                <span v-html="pageTitle"></span><span v-if="state.bordereau.bor_n_readonly" class="text-danger fw-bolder">({{$t("LECTURE SEULE")}})</span>
              </h1>
              
            </li>

            <li class="nav-item" style="float: right;">
              <el-tooltip :content="$t('Créer un nouvel article')" placement="top-end">
                <a
                  class="btn btn-custom-red text-active-light me-6 ml-6 h-45px"
                  style='margin-left: 10px'
                  @click="newEditArticle({art_n_seq: 0},0)"
                >
                  <span class="svg-icon svg-icon-1 svg-icon-white" style=" margin-right: 0rem;"><inline-svg src="media/icons/duotune/arrows/arr017.svg"/> </span>
                </a>
              </el-tooltip>
            </li>
            <li class="nav-item" style="float: right;">
              
              <el-dropdown>
                <el-tooltip :content="$t('Exporter le détail du bordereau')" placement="top-end">
                  <a class="btn btn-secondary text-active-light me-6 ml-6 btn-custom-white h-45px">
                    <span class="svg-icon svg-icon-1 svg-icon-white" style=" margin-right: 0rem;"><inline-svg src="media/icons/duotune/arrows/arr044.svg"/> </span>
                  </a>
                </el-tooltip>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item @click="csvExport()">{{$t("Export Arkheïa")}}</el-dropdown-item>
                    <el-dropdown-item disabled>{{$t("Export XML")}}</el-dropdown-item>
                    <el-dropdown-item disabled>{{$t("Export PDF")}}</el-dropdown-item>
                    <el-dropdown-item disabled>{{$t("Export JSON")}}</el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>

            </li>
            <li class="nav-item mx-4 " style="float: right;" >
              <BordPDF title="" class="btn btn-secondary text-active-light me-6 ml-6 btn-custom-white h-45px" :bor_n_seq="state.bor_n_seq" />
            </li>


          </ul>
        </div>


      <div class="card" style="width: 100%;">
        <div class="card-header border-0 pt-6">
          <div class="card-title" style="width:100%;">
            <h2>{{$t("Liste des articles")}} {{ bordType }}</h2>
          </div>
          <SearchAndFilter
            v-if="state.initialMyList && !state.loaderEnabled"
            :listInitial="state.initialMyList"
            :filters="tableFilters"
            @returnSearch="state.myList = $event"
            @returnLoadingDatatable="state.loadingDatatable = $event"
          ></SearchAndFilter>
        </div>
        <div class="card-body pt-0">

          <div
            v-if="state.myList.length == 0"
            class="alert bg-light-primary alert-primary d-flex align-items-center p-5 mb-10"
          >
            <span class="svg-icon svg-icon-2hx svg-icon-primary me-4">
              <inline-svg src="media/icons/duotune/general/gen048.svg" />
            </span>
            <div class="d-flex flex-column">
              <h4 class="mb-1 text-dark">{{$t("Aucune ligne à afficher")}}</h4>
              <span>{{$t("Il n'existe aucune ligne à afficher correspondant à vos critères de recherche.")}}</span>
            </div>
          </div>
          <Datatable
            v-if="state.myList && state.myList.length > 0"
            :table-data="state.myList"
            :table-header="tableHeader"
            :enable-items-per-page-dropdown="true"
            :rows-per-page="50"
            :empty-table-text="$t('Aucune ligne à afficher.')"
            :key="state.loadingDatatable"
            class="table-small-td"
            @click-function="newEditArticle($event,0)"
          >

            <template v-slot:cell-art_n_num_ordre="{ row: data }"><div class="mx-2">{{ data.art_n_num_ordre }}</div></template>
            <template v-slot:cell-art_c_categorie="{ row: data }">
              <div class="fs-7">{{ data.art_c_categorie }}</div>
              <div class="fs-6 text-dark">{{ data.art_c_libelle }}</div>

              <div class="display-small fs-7 text-blue">{{$t("Dates :")}} <b>{{ data.art_n_annee_mini }} - {{ data.art_n_annee_maxi }}</b> {{$t("/ DUA :")}} <b>{{ data.art_n_dua }}</b></div>
              <div class="display-small fs-6"><span class="badge badge-success" v-if="data.art_c_observations">{{$t("Observation")}}</span></div>

            </template>
            <template v-slot:cell-art_n_dua="{ row: data }">{{ data.art_n_dua }}</template>
            <template v-slot:cell-art_n_annee_mini="{ row: data }"><span class="text-blue">{{ data.art_n_annee_mini ? data.art_n_annee_mini + " - " + data.art_n_annee_maxi : "s.d." }}</span></template>
            <template v-slot:cell-art_c_observations="{ row: data }">
              <span class="badge badge-success" v-if="data.art_c_observations">{{$t("Oui")}}</span>
            </template>
            <template v-slot:cell-art_n_seq="{ row: data }">
              <div v-if="!state.bordereau.bor_n_readonly">
                <el-popconfirm
                  :confirm-button-text="$t('Oui, Supprimer')"
                  :cancel-button-text="$t('Non')"
                  :title="$t('Confirmez-vous la suppression de la ligne ?')"
                  @confirm="deleteArticle(data.art_n_seq)"
                >
                  <template #reference>  
                    <a class="btn btn-sm btn-light-danger btn-custom-white mx-1 btn-mid-width" >
                      <span class="svg-icon svg-icon-1 svg-icon-white" style=" margin-right: 0rem;">
                      <inline-svg src="media/icons/duotune/arrows/arr061.svg"/> </span>
                    </a>
                  </template>
                </el-popconfirm>
                
                <a class="btn btn-sm btn-custom-white  mx-1 btn-mid-width" @click='newEditArticle(data,1)' @click.stop>
                  <span class="svg-icon svg-icon-1 svg-icon-primary" style=" margin-right: 0rem;">
                    <inline-svg src="media/icons/duotune/general/gen054.svg"/> </span>
                </a>

                <!--
                <a class="btn btn-sm btn-custom-white mx-1 " @click='newEditArticle(data,0)'>
                  <span class="svg-icon svg-icon-1 svg-icon-blue" style=" margin-right: 0rem;">
                    <inline-svg src="media/icons/duotune/general/gen004.svg"/> </span>
                    Afficher
                </a>
                -->

                </div>
            </template>
          </Datatable>
        </div>
      </div>


    </div>
  </div>
  </transition>


  <el-dialog v-model="state.drawer" :title="`${form.art_n_seq > 0 ? $t('Modification') : $t('Création')} ${$t('d\'un article')}`" width="800">
    <div class="row" style="text-align: center" >
        <div class="col label-modal mx-3 p-2">  
          <div class="p-3 mx-4 px-7 " style="float: left; border: 0px solid #cbc7c1;">
            <span class="svg-icon svg-icon-1 svg-icon-primary" style=" margin-right: 0rem;" :class="{'svg-icon-success': form.art_n_num_ordre}">
              <inline-svg v-show="!form.art_n_num_ordre" src="media/icons/duotune/arrows/arr061.svg"/>
              <inline-svg v-show="form.art_n_num_ordre" src="media/icons/duotune/arrows/arr016.svg"/>
            </span>
            <span class="fs-5"> {{$t("Numéro d'ordre")}} </span>
          </div>
          <div class="p-3 mx-4 px-7 " style="float: left; border: 0px solid #cbc7c1;">
            <span class="svg-icon svg-icon-1 svg-icon-primary" style=" margin-right: 0rem;" :class="{'svg-icon-success': form.art_c_libelle}">
              <inline-svg v-show="!form.art_c_libelle" src="media/icons/duotune/arrows/arr061.svg"/>
              <inline-svg v-show="form.art_c_libelle" src="media/icons/duotune/arrows/arr016.svg"/>
            </span>
            <span class="fs-5"> {{$t("Libellé")}} </span>
          </div>
          <div class="p-3 mx-4 px-7 " style="float: left; border: 0px solid #cbc7c1;">
            <span class="svg-icon svg-icon-1 svg-icon-primary" style=" margin-right: 0rem;" :class="{'svg-icon-success': (form.art_n_annee_mini&&form.art_n_annee_maxi)||form.art_x_sans_date}">
              <inline-svg v-if="(form.art_n_annee_mini&&form.art_n_annee_maxi)||form.art_x_sans_date" src="media/icons/duotune/arrows/arr016.svg"/>
              <inline-svg v-else src="media/icons/duotune/arrows/arr061.svg"/>
            </span>
            <span class="fs-5"> {{$t("Dates")}} </span>
          </div>
          <div class="p-3 mx-4 px-7 " style="float: left; border: 0px solid #cbc7c1;">
            <span class="svg-icon svg-icon-1 svg-icon-primary" style=" margin-right: 0rem;" :class="{'svg-icon-success': form.art_c_observations}">
              <inline-svg v-show="!form.art_c_observations" src="media/icons/duotune/arrows/arr061.svg"/>
              <inline-svg v-show="form.art_c_observations" src="media/icons/duotune/arrows/arr016.svg"/>
            </span>
            <span class="fs-5"> {{$t("Observation")}} </span>
          </div>
        </div>
    </div>

    <div class="row row-user">
        <div class="col-sm-4 col-xxl-2 p-5 label-modal"><span style="font-size: 15px;"><strong>{{$t("N° d'ordre")}}</strong></span></div>
        <div class="col"> 
          <el-input v-model="form.art_n_num_ordre" :placeholder="$t('N° d\'ordre')" class="m-2" style='float: left; width: 120px;' /> 
        </div>
    </div>

    <div class="row row-user">
        <div class="col-sm-4 col-xxl-2 p-5 label-modal"><span style="font-size: 15px;"><strong>{{$t("Titre")}}</strong></span></div>
        <div class="col"> 
          <el-select v-model="form.art_c_categorie" filterable allow-create default-first-option :reserve-keyword="false" :placeholder="$t('Titre')" class="m-2" style='float: left; width: 100%' >
            <el-option v-for="item in state.lCateg"  :key="item.value" :label="item.value" :value="item.value" />
          </el-select>
        </div>
    </div>

    <div class="row row-user">
        <div class="col-sm-4 col-xxl-2 p-5 label-modal"><span style="font-size: 15px;"><strong>{{$t("Libellé")}}</strong></span></div>
        <div class="col"> 
          <el-input v-model="form.art_c_libelle" type="textarea" autosize :placeholder="$t('Libellé')" class="m-2" /> 
        </div>
    </div>

    <div class="row row-user">
        <div class="col-sm-4 col-xxl-2 p-5 label-modal"><span style="font-size: 15px;"><strong>{{$t("Dates extrêmes")}}</strong></span></div>
        <div class="col"> 
          <el-checkbox class="m-2" v-model="form.art_x_sans_date" :label="$t('Sans date')" size="large" border style='float: left;'/>
          <el-input clearable  v-if="!form.art_x_sans_date" v-model="form.art_n_annee_mini" :placeholder="$t('Année de début')" class="m-2" style='float: left; width: 150px;' /> 
          <el-input clearable  v-if="!form.art_x_sans_date" v-model="form.art_n_annee_maxi" :placeholder="$t('Année de fin')" class="m-2" style='float: left; width: 150px;' /> 
        </div>
    </div>

    <div class="row row-user">
        <div class="col-sm-4 col-xxl-2 p-5 label-modal"><span style="font-size: 15px;"><strong>{{$t("Métrage Linéaire")}}</strong></span></div>
        <div class="col"> 
          <el-input clearable v-model="form.art_metrage_lineaire" :placeholder="$t('Saisir')" class="m-2" style='float: left; width: 150px;' >
            <template #append>
              ml
            </template>
          </el-input>
        </div>
    </div>
    

    <div class="row row-user">
        <div class="col-sm-4 col-xxl-2 p-5 label-modal"><span style="font-size: 15px;"><strong>{{$t("DUA spécifique")}}</strong></span></div>
        <div class="col"> 
          <el-input v-model="form.art_n_dua" :placeholder="$t('DUA')" class="m-2" style='float: left; width: 470px;' > 
            <template #append>
              {{$t("Années")}} <i>({{$t("A spécifier si différent du bordereau")}})</i>
            </template>
          </el-input>
        </div>
    </div>

    <div class="row row-user">
        <div class="col-sm-4 col-xxl-2 p-5 label-modal"><span style="font-size: 15px;"><strong>{{$t("Observations")}}</strong></span></div>
        <div class="col"> 
          <el-input v-model="form.art_c_observations" type="textarea" autosize :placeholder="$t('Observations')" class="m-2" /> 
        </div>
    </div>

    <div class="row row-user">
        <div class="col-sm-4 col-xxl-2 p-5 label-modal"><span style="font-size: 15px;"><strong>{{$t("Référence")}}<br>{{$t("réglementaire")}}</strong></span></div>
        <div class="col"> 
          <el-input v-model="form.art_c_ref_reglemantaire" type="textarea" :row='3' :placeholder="$t('Facultatif...')" class="m-2" /> 
        </div>
    </div>
   


    <template #footer>
      <span class="dialog-footer">
        <el-button class="btn btn-secondary" @click="state.drawer = false">{{$t("Annuler")}}</el-button>
        <el-button class="btn btn-primary" @click="addEditArticle" :disabled="(!form.art_n_num_ordre)||(!form.art_c_libelle)||(!((form.art_n_annee_mini&&form.art_n_annee_maxi)||form.art_x_sans_date))">
          {{form.art_n_seq > 0 ? $t("Modifier l'article") : $t("Créér l'article")}}
        </el-button>
      </span>
    </template>
  </el-dialog>


</template>

<script lang="ts">
import { defineComponent, ref, onMounted, reactive, computed } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { useStore } from "vuex";
import * as moment from "moment";
import SearchAndFilter from "@/views/still/search_and_filters.vue";
import BordPDF from "@/views/still/bord/pdf.vue"; 
import { ElNotification } from 'element-plus'
import mAxiosApi from "@/api";
import { useI18n } from "vue-i18n";
import { formatDateLang } from "@/App.vue";

export default defineComponent({
  name: "Overview",
  components: {
    Datatable,
    SearchAndFilter,
    BordPDF
  },
  setup() {
    const { t } = useI18n()
    const store = useStore();
    const pageTitle = computed(() => {
      return store.getters.pageTitle;
    });

    const bordType = computed(() => {

      if (store.getters.bordType == "versement") return t('de versement')
      return t("d'élimination")
      
    });




    //moment.default.locale("fr");

    const currentBord = parseInt(localStorage.getItem("currentBord") || "0");

    const state = reactive({
      bor_n_seq : currentBord,
      bordereau : {} as any,
      loaderEnabled: true,
      myList: [] as any,
      drawer: ref(false),
      initialMyList: [],
      loadingDatatable: 0,
      myListLiv: [],
      lCateg: [] as any,

    });
    

    const form = reactive({
      art_n_seq: 0,
      art_n_num_ordre: "0",
      art_c_libelle : '',
      art_n_annee_mini : '',
      art_n_annee_maxi : '',
      art_x_sans_date : false,
      art_c_observations: '',
      art_knum_bordereau: 0,
      art_c_categorie: '',
      art_n_dua: '',
      art_c_ref_reglemantaire: '',
      art_metrage_lineaire: '',
    })



    const tableHeader = ref([
      { name: "N° d'ordre", key: "art_n_num_ordre", sortable: false, className:"w-100px"},
      { name: "Titre / Libellé", key: "art_c_categorie", sortable: false,},
      { name: "Dates", key: "art_n_annee_mini", sortable: false, className:"hide-small"},
      { name: "DUA", key: "art_n_dua", sortable: false, className:"hide-small"},
      { name: "Observations", key: "art_c_observations", sortable: false, className:"hide-small"},
      { name: "", key: "art_n_seq", sortable: false, className:"w-125px"},

    ]);

    const tableFilters = ref([
      { name: "titre", key: "art_c_categorie", label: "art_c_categorie", },
    ]);
    const formatDate = (d) => {
      return moment.default(d).format(formatDateLang);
    };
    const formatDateAgo = (d) => {
      return moment.default(d, "YYYY-MM-DD").fromNow();
    };



    const newEditArticle = async (mArt, xCopy: number) => {
      //console.log("bordereau",state.bordereau.bor_n_dua);
      const art_n_seq = mArt.art_n_seq
      if (art_n_seq == "0") {
        const tmpList = await loadArticles(0);
        form.art_n_num_ordre = tmpList.length > 0 ? (parseInt(tmpList[tmpList.length - 1].art_n_num_ordre) + 1).toString(): "1";
        form.art_n_seq = art_n_seq;
        form.art_c_libelle = '';
        form.art_n_annee_mini = '';
        form.art_n_annee_maxi = '';
        form.art_x_sans_date = false;
        form.art_c_observations = '';
        form.art_knum_bordereau = currentBord;
        form.art_c_categorie = '';
        form.art_n_dua = state.bordereau.bor_n_dua;
        form.art_c_ref_reglemantaire = '';
        form.art_metrage_lineaire = '';
      } else {
        const myArtTmp = await getAxios("/getSVArticlesById/" + art_n_seq);
        const myArt = myArtTmp.results

        form.art_n_num_ordre = myArt.art_n_num_ordre;
        form.art_n_seq = myArt.art_n_seq;
        form.art_c_libelle = myArt.art_c_libelle;
        form.art_n_annee_mini = myArt.art_n_annee_mini;
        form.art_n_annee_maxi = myArt.art_n_annee_maxi;
        form.art_x_sans_date = myArt.art_x_sans_date == "1" ? true : false;
        form.art_c_observations = myArt.art_c_observations;
        form.art_knum_bordereau = myArt.art_knum_bordereau;
        form.art_c_categorie = myArt.art_c_categorie;
        form.art_n_dua = myArt.art_n_dua;
        form.art_c_ref_reglemantaire = myArt.art_c_ref_reglemantaire;
        form.art_metrage_lineaire = myArt.art_metrage_lineaire;

        if (xCopy == 1) {
          form.art_n_seq = 0;
          const tmpList = await loadArticles(0);
          form.art_n_num_ordre = tmpList.length > 0 ? (parseInt(tmpList[tmpList.length - 1].art_n_num_ordre) + 1).toString(): "1";
        }

      }
      
      const lCateg = await getAxios("/getSVArticlesByCateg/" + currentBord);
      state.lCateg = lCateg.results;

      state.drawer = true;
    };

    const deleteArticle = async (art_n_seq) => {
      form.art_n_seq = art_n_seq;
      mAxiosApi
        .post("/deleteArticle", form)
        .then( async() => {
          ElNotification({ title: t('Succès'), message: t("Article supprimé de la liste."), type: 'success', })
          await loadArticles(1);
          state.loadingDatatable = state.loadingDatatable + 1
          state.drawer = false;
        })
    };

    const addEditArticle = async () => {

      form.art_n_annee_mini = !form.art_x_sans_date ? form.art_n_annee_mini : "";
      form.art_n_annee_maxi = !form.art_x_sans_date ? form.art_n_annee_maxi : "";
      form.art_c_ref_reglemantaire = !form.art_c_ref_reglemantaire ? "" : form.art_c_ref_reglemantaire;


      if (!form.art_x_sans_date) {
        const globalRegex = new RegExp('^[12][0-9]{3}$', 'g');
        if (globalRegex.test(form.art_n_annee_mini) == false ) {
          ElNotification({ title: t("Erreur"), message: t("L'année début semble être incorrecte. Veuillez vérifier votre saisie, svp"), type: 'error', })
          return false;
        }
        const testFin = globalRegex.test(form.art_n_annee_maxi);
        if (globalRegex.test(form.art_n_annee_maxi) == false) {
          ElNotification({ title: t("Erreur"), message: t("L'année de fin semble être incorrecte. Veuillez vérifier votre saisie, svp"), type: 'error', })
          return false;
        }
        if (form.art_n_annee_maxi < form.art_n_annee_mini) {
          ElNotification({ title: t("Erreur"), message: t("L'année de fin ne peut être inférieur à l'année de début. Veuillez vérifier votre saisie, svp"), type: 'error', })
          return false;
        }
      }
      if(form.art_metrage_lineaire) {
        if(!(!isNaN(parseFloat(form.art_metrage_lineaire)) && isFinite(form.art_metrage_lineaire as any))) {
          ElNotification({ title: t("Erreur"), message: t("Le métrage linéaire doit être un nombre ou vide. Veuillez vérifier votre saisie, svp"), type: 'error', })
          return false;
        }
      }

      mAxiosApi
        .post("/addEditArticle", form)
        .then( async() => {
          state.loaderEnabled = true;
          const mNotif = form.art_n_seq > 0 ? t("Article modifié avec succès !") : t("Article créé avec succès !");
          ElNotification({ title: t("Succès"), message: mNotif, type: 'success', })
          await loadArticles(1);
          state.loadingDatatable = state.loadingDatatable + 1
          state.drawer = false;
          state.loaderEnabled = false;
        })
        
    };

    const loadArticles = async (xReload = 1) => {
      let myListLiv = {} as any;
      myListLiv = await getAxios("/getSVArticles/" + currentBord);
      if (xReload) {
        state.myList = myListLiv.results;
        state.initialMyList = state.myList;
        state.loadingDatatable = state.loadingDatatable + 2
      }
      return myListLiv.results

    }


    const csvExport = async () => {
      
      const mArray = [] as any;     
      state.initialMyList.forEach(element => {
        let myElem = JSON.stringify(element);
        
        myElem = myElem.replaceAll('\\n', " ");
        myElem = myElem.replaceAll('\\r', " ");
        myElem = myElem.replaceAll(";", '.');        
        mArray.push(JSON.parse(myElem));
      });

      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(mArray[0]).join(";"),
        ...mArray.map(item => Object.values(item).join(";"))
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      
      const filename = "SV_Export_Bordereau_1324_" + moment.default().format("DD.MM.YYYY_H:mm:ss") + ".csv";

      link.setAttribute("download", filename);
      link.click();
    };


    onMounted(async () => {

      const myListLiv = await getAxios("/getSVoneBord/"+ currentBord);
      state.bordereau = myListLiv.results

      await loadArticles(1);
      state.loaderEnabled = false;

      //console.log("Initial !",state.initialMyList);

    });


    return {
      tableHeader,
      state,
      formatDate,
      formatDateAgo,
      tableFilters,
      form,
      addEditArticle,
      newEditArticle,
      deleteArticle,
      csvExport,
      pageTitle,
      bordType
    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}
</script>
